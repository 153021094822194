.is-fullheight
  height: 100%

.is-fullwidth
  width: 100%

.has-click
  cursor: pointer

.is-absolute
  position: absolute

.has-text-orange
  color: orange
