.typeahead-results-container
  z-index: 9999
  position: absolute
  width: 450px
  padding: 0px

  .result:first-child
    margin-bottom: 0px !important

  .result
    padding-left: 1rem
    padding-right: 1rem

  .result:not(:last-child)
    border-bottom-style: solid
    border-bottom-width: 1px
    border-bottom-color: whitesmoke

.typeahead-loading-spinner
  right: 40px
  position: relative

.typeahead-header
  border-bottom-style: solid
  border-bottom-width: 1px
  border-bottom-color: #cdcdcd
  background-color: #e5e4e4

.typeahead-loading-spinner-container
  position: absolute
  right: -25px
