.darkmode-toggle
  z-index: 9999

.darkmode--activated
  .navbar
    background-color: #121212

    .navbar-item:hover,
    :active,
    :focus
      background-color: #121212

  .darkmode-layer
    mix-blend-mode: exclusion !important

  .additional-info-container
    color: var(--primary)

  .button,
  .textarea,
  .input
    background-color: #121212
    color: #858585
    border-color: #858585

  .button.is-primary
    color: #ffffff
    background-color: var(--primary)

  .is-primary
    mix-blend-mode: difference

  .is-danger
    mix-blend-mode: difference

  .has-text-primary
    mix-blend-mode: difference

  .is-checkradio[type="radio"] + label::before,
  .is-checkradio[type="radio"] + label:before,
  .is-checkradio[type="checkbox"] + label::before,
  .is-checkradio[type="checkbox"] + label:before
    background-color: #121212
