.panel-heading
  font-size: 1em

.button
  font-size: 0.8em

.tabs
  font-size: 0.8em

.has-float-label .input
  height: 50px

.has-float-label .input:placeholder-shown:not(:focus) ~ label,
.has-float-label .input:not(.has-value):not(:focus) ~ label
  top: 13px
